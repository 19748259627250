<template>
     <section class="login flex">
          <div class="item null-item flex align-center justify-center">
               <div class="welcome-title text-center">
                    <img class="welcome" src="@/assets/images/login/welcome.png" alt="">
                    <img class="title" src="@/assets/images/login/title.png" alt="">
               </div>
          </div>
          <div class="item form-content">
               <div class="form-header text-center">
                    <img src="../../assets/images/logo.png" />
                    <div class="name">城投置业保障房管理平台</div>
               </div>
               <el-form class="login-form text-center" :model="formData">
                    <el-input
                            clearable
                            v-model="formData.username"
                            placeholder="请输入账号"
                    >
                         <img class="icon-img" slot="prefix" src="../../assets/images/login/account.png" />
                    </el-input>
                    <el-input
                            show-password
                            clearable
                            v-model="formData.password"
                            placeholder="请输入登录密码"
                    >
                         <img class="icon-img" slot="prefix" src="../../assets/images/login/password.png" />
                    </el-input>

                    <el-button class="submit-btn" @click="handleSubmit">登 录</el-button>
               </el-form>
          </div>
     </section>
</template>

<script>
    import { loginApi } from "@/api/login";
    import { MessageSuccess, MessageWarning } from "@custom/message";
    import { tokenCookies } from "@storage/cookies";

    export default {
        name: "login",
        data() {
            return {
                formData: {
                    username: "",
                    password: "",
                }
            };
        },
        methods: {
            handleSubmit() {
                const { username, password } = this.formData;
                if (!username) {
                    MessageWarning("请输入账号");
                    return;
                }
                if (!password) {
                    MessageWarning("请输入密码");
                    return;
                }
                loginApi(this.formData).then(() => {
                    MessageSuccess("登录成功");
                    tokenCookies.set("token");
                    this.$router.push("/home");
                });
            }
        }
    };
</script>
<style lang="scss" rel="stylesheet/scss">
     .login input {
          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &:-moz-placeholder,
          &:-ms-input-placeholder {
               font-weight: 400;
               font-family: PingFang SC Regular;
          }
     }
</style>
<style lang="scss" scoped>
     .login {
          width: 100vw;
          height: 100vh;

          .item {
               height: 100%
          }

          .null-item {
               width: VW(1064px);
               background: url("../../assets/images/login/background.png") no-repeat;
               background-size: 100% 100%;

               .welcome-title {
                    width: 100%;
                    position: relative;

                    .welcome {
                         width: VW(404px);
                         position: relative;
                         top: VH(-100px);
                    }

                    .title {
                         width: VW(153px);
                         float: right;
                         position: absolute;
                         top: VH(50px);
                         right: 0;
                    }
               }

          }

          .form-content {
               width: VW(856px);
               padding: VH(70px) VW(50px);

               .form-header {
                    color: #333;
                    font-weight: 600;
                    font-size: rem(50px);
                    font-family: PingFang SC Semibold;

                    img {
                         width: VW(140px);
                    }

                    .name {
                         padding: VH(60px) 0;
                    }
               }

               /deep/ .login-form {
                    .el-input {
                         width: VW(500px);
                         height: VH(49px);
                         background: #fff;
                         border-bottom: 1px solid #D7D7D7;
                         display: block;
                         margin: 0 auto VH(30px);

                         .el-input__inner {
                              font-size: rem(16px);
                              padding-left: VW(30px);
                              border: none;
                              background: transparent;
                         }

                         .el-input__prefix {
                              left: 0;
                              top: VH(12px);

                              .icon-img {
                                   width: VW(17px);
                              }
                         }
                    }

                    .submit-btn {
                         color: #fff;
                         width: VW(382px);
                         padding: VH(20px) 0;
                         letter-spacing: 5px;
                         margin-top: VH(64px);
                         font-size: rem(30px);
                         font-family: PingFang SC Regular;
                         font-weight: 400;
                         box-shadow: 0 6px 6px rgba(41, 150, 255, 0.45);
                         background-color: #346AF9;
                    }
               }
          }
     }
</style>
